html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 11px;
  color: #333;
}
a {
  text-decoration: none;
}
.Heading1 {
  font-size: 11px;
  color: black;
}
.Heading3 {
  font-weight: bold;
  font-size: 10px;
  color: red;
  /* font-family: Tahoma, Helvetica, sans-serif; */
}
.BackgroundIndex {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  background-color: #2a2a2a;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  background-image: url(./Images/index/bg.jpg?modified=20190912);
  background-repeat: no-repeat;
  background-size: cover;
}
.btn {
  cursor: pointer;
  padding-bottom: 2px;
  font-size: 11px;
  line-height: 15px;
  background-repeat: repeat-x;
  background-position: 50% top;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  text-decoration: none;
  margin-left: 4px;
}
.btnBlue {
  color: #ffffff;
  border: 1px #587ab1 solid;
  background-image: url("./Images/btnBlue.jpg");
}
.btn:hover {
  border: 1px #d1591f solid;
  color: #ffffff;
  background-image: url("./Images/btnOV.jpg");
  background-repeat: repeat-x;
  text-decoration: none;
  text-shadow: -1px -1px 1px #d2591f;
}
.btnSpec {
  padding-bottom: 0px;
}
.topstyle {
  color: #333;
  background-color: #e8eff5;
  /*height: 118px;*/
  font-family: Tahoma;
  font-size: 11px;
  min-width: 1000px;
  overflow: hidden;
}
.trHeader {
  background-color: black;
  height: 92px;
  display: grid;
  grid-template-columns: 740px 235px;
}
.tHLup {
  height: 64px;
  display: grid;
  grid-template-columns: 400px auto;
}
.timelang {
  height: 20px;
  display: grid;
  grid-template-columns: auto 120px;
  padding: 0px 10px 0px 0px;
  margin-top: 34px;
}
.CssHdrTime {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  text-size-adjust: 100%;
  text-align: right;
}
.menuLang {
  height: 20px;
  width: 110px;
  float: right;
  display: grid;
  grid-template-columns: 20px 90px;
  background-image: linear-gradient(to bottom, #f9f9f9, #d8d8d8);
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  line-height: 15px;
  margin-top: 1px;
}
.menuLang div img {
  margin-top: 4px;
  vertical-align: middle;
}
.tHLbot {
  display: table;
  height: 28px;
}
.tHLbot div {
  display: table-cell;
  height: 28px;
}
.HdrTabBga6L {
  background-image: url("./Images/a6.png");
  background-position: left;
  text-align: center;
  width: 5px;
}
.HdrTabBga6C {
  background-image: url("./Images/a6.png");
  background-position: center;
  text-align: center;
  padding-left: 7px;
  padding-right: 7px;
  white-space: nowrap;
  vertical-align: middle;
}
.HdrTabBga6R {
  background-image: url("./Images/a6.png");
  background-position: right;
  text-align: center;
  width: 5px;
}
.ellipsis_header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.tHLbot A:link {
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}
.tHLbot A:visited {
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}
.tHLbot A:active {
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}
.tHLbot A:hover {
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #fc0;
  text-decoration: none;
  font-weight: bold;
}
.blockUser {
  width: 235px;
  height: 67px;
  background-color: #ffffff;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
}
.userCredit {
  display: grid;
  grid-template-columns: auto 4px auto;
  height: 30px;
  padding: 7px 5px;
}
.userCredit div {
  text-align: center;
  padding: 1px 0px;
}
.btnBlock {
  height: 23px;
  background-color: #725b29;
  line-height: 23px;
  text-align: center;
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
}
.trBar {
  color: #ffffff;
  background-color: #745929;
  height: 26px;
}
.trBarGrid {
  display: grid;
  grid-template-columns: 15px auto 20px 20px 10px 500px;
  height: 26px;
  width: 975px;
}
.ellipsis_bar {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.spMargin {
  margin: 0px 8px;
}
.class2 {
  display: table;
  height: 26px;
}
.class2Td {
  display: table-cell;
  vertical-align: middle;
  padding: 0;
}
.class2 A:link {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}
.class2 A:visited {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}
.class2 A:active {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}
.class2 A:hover {
  font-family: Arial;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #fc0;
  text-decoration: none;
  font-weight: bold;
}
.HdrMsgBg {
  background-color: #ffffff;
  padding-left: 2px;
  border-top: 3px solid #745929;
  border-bottom: 3px solid #745929;
  vertical-align: middle;
}
.CssHdrMsg {
  /*-webkit-marquee: auto medium infinite scroll normal;*/
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  color: #000000;
  line-height: 1.42857143;
  margin-top: 2px;
}
.CssHdrMsg span {
  display: inline-block;
  padding-left: 100%; /* show the marquee just outside the paragraph */
  animation: CssHdrMsg 40s linear infinite;
  -webkit-animation: CssHdrMsg 40s linear infinite;
}
@keyframes CssHdrMsg {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@-webkit-keyframes CssHdrMsg {
  0% {
    -webkit-transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
  }
}

.container {
  display: grid;
  grid-template-columns: 200px 800px auto;
  grid-gap: 0rem;
  min-width: 1000px;
  overflow: hidden;
  height: calc(100% - 118px);
}
.trHeader_toogle_top {
  display: none;
}
.container_toogle_top {
  height: calc(100% - 26px);
}
.leftstyle_toogle_left {
  display: none;
}
.container_toogle_left {
  grid-template-columns: 800px auto;
}
.leftstyle {
  background-color: #b5b5b5;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  height: 100%;
  overflow-y: auto;
}
.blockLeft {
  margin: auto;
  height: 100%;
}
.leftHx {
  height: 5px;
}
.PanelMyFavBg {
  background-image: url(./Images/myFavourite.png);
  background-repeat: no-repeat;
  width: 174px;
  height: 24px;
  margin: auto;
  display: table;
}
.PanelSubHdrLbl {
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  padding-left: 30px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}
.trSportsMenu,
.trStake {
  width: 174px;
  margin: auto;
  display: table;
}
.PanelHideMenuBg {
  background-image: url(./Images/hideMenu.png);
  background-repeat: no-repeat;
  width: 174px;
  height: 24px;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}
.PanelShowMenuBg {
  background-image: url(./Images/showMenu.png);
  background-repeat: no-repeat;
  width: 174px;
  height: 24px;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}
.trToggleOtMenu {
  display: table-row;
  vertical-align: middle;
}
.OtBg {
  background-image: url(./Images/marketBg.png);
  background-repeat: repeat-x;
  display: grid;
  grid-template-columns: auto 2px auto;
  height: 24px;
}
.HeadingOt1 {
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  line-height: 24px;
  font-size: 11px;
  text-shadow: -1px -1px 0px #1d2b44;
  cursor: pointer;
}
.HeadingOt2 {
  color: #f7d400;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
  font-size: 11px;
  text-shadow: -1px -1px 0px #1d2b44;
  cursor: pointer;
  background-image: url(./Images/marketBgh.png);
  background-repeat: no-repeat;
  background-position: center top;
}
.PanelItemGrid {
  background-color: #bbbbbb;
  border: 1px solid #000;
}
.SportsMenuBg {
  background-color: #ffffff;
}
.SpMenuHeaderBg {
  background-image: url(./Images/sportsMenuBg.png);
  background-repeat: no-repeat;
  height: 25px;
  display: table;
  cursor: pointer;
}
.SpMenuHeaderBg div {
  display: table-cell;
  vertical-align: middle;
}
.SpMenuHeader {
  font-size: 11px;
  color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
}
.SpMenuItem {
  font-size: 11px;
  color: #333;
  cursor: pointer;
  text-decoration: none;
  background-image: url(./Images/sportsMenuItemBg.png);
  background-repeat: repeat-y;
  display: table;
}
.SpMenuItem div {
  display: table-cell;
  vertical-align: middle;
}
.SpMenuHeader A:link {
  color: #ffffff;
  text-decoration: none;
}
.SpMenuHeader A:visited {
  color: #ffffff;
  text-decoration: none;
}
.SpMenuHeader A:active {
  color: #ffffff;
  text-decoration: none;
}
.SpMenuHeader A:hover {
  color: #f30;
  text-decoration: none;
}

.SpMenuItem A:link {
  color: #333;
  text-decoration: none;
}
.SpMenuItem A:visited {
  color: #333;
  text-decoration: none;
}
.SpMenuItem A:active {
  color: #333;
  text-decoration: none;
}
.SpMenuItem A:hover {
  color: #f30;
  text-decoration: none;
}
.SpCnt {
  color: #ff0000;
  height: 20px;
  padding-right: 5px;
}
.mainstyle {
  background-color: #e5e5e5;
  font-family: Tahoma;
  font-size: 11px;
  height: 100%;
  overflow-y: auto;
  position: relative;
}
.Background {
  background-color: #e5e5e5;
  font-family: Tahoma;
  font-size: 11px;
}
.Backgroundx {
  background-color: #e5e5e5;
  font-family: Arial;
  font-size: 11px;
  color: #000;
}
.sportTB {
  display: grid;
  grid-template-columns: 2px auto;
}
.preferenceTB {
  display: grid;
  grid-template-columns: 5px auto;
}
.newsTB {
  display: grid;
  grid-template-columns: 12px auto;
}
.tbBarOption,
.optCont {
  display: table;
  height: 25px;
}
.StrStyleSoc {
  font-weight: normal;
  font-size: 11px;
}
.GridTitle {
  font-size: 13px;
  font-weight: bold;
  color: #022352;
}
.btnStep {
  font-family: Arial;
  font-size: 11px;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 20px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
.btnStep:hover {
  font-family: Arial;
  font-size: 11px;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 20px;
  text-align: center;
  color: #fc0;
  text-decoration: none;
  font-weight: bold;
}
.btnGrey span {
  background-image: url("./Images/btnGrey.jpg");
  background-repeat: repeat-x;
  background-position: 50% top;
  border: 1px #bcbaba solid;
  color: #000000;
  padding-top: 1px;
  padding-right: 2px;
  padding-bottom: 2px;
  padding-left: 2px;
  height: 19px;
  line-height: 19px;
  font-size: 11px;
  cursor: pointer;
  text-decoration: none;
  margin-left: 2px;
}
.btnGrey:hover span {
  background-image: url("./Images/btnOV.jpg");
  background-repeat: repeat-x;
  border: 1px #d1591f solid;
  color: #ffffff;
  text-decoration: none;
  text-shadow: -1px -1px 1px #d2591f;
}
/* Odds Drop Down List Css */
.OddsSel {
  border: 1px #b1b1b1 solid;
  background: #f2f2f2;
  text-align: left;
  margin-top: 2px;
  z-index: 76;
}

.OddsSel2Bg {
  border: 1px #f2f2f2 solid;
  padding: 0 0 0 2px;
}

.OddsSel2Bg:hover {
  border: 1px #d1591f solid;
  background: #ffffff;
}

.OddsSel2 {
  color: #000000;
  line-height: 20px;
}

.OddsSel2:link {
  text-decoration: none;
  color: #000000;
}

.OddsSel2:visited {
  text-decoration: none;
  color: #000000;
}

.OddsSel2:hover {
  text-decoration: none;
  color: #d1591f;
}

.OddsSel2:active {
  text-decoration: none;
  color: #000000;
}

.divOddsSel {
  background: url("./Images/btnSelect.jpg") no-repeat right;
  border: 1px #bcbaba solid;
  color: #000000;
  padding-top: 1px;
  padding-right: 22px;
  padding-left: 2px;
  margin-left: 2px;
  font-size: 11px;
  cursor: pointer;
  text-align: left;
  height: 15px;
  margin-top: 1px;
}
.divOddsSel:hover {
  background: url("./Images/btnSelectOV.jpg") no-repeat right;
  border: 1px #d1591f solid;
  color: #ffffff;
  cursor: pointer;
}
/* Odds Drop Down List Css END */
.morebets {
  background-color: #e5eef3;
  border: 0px solid black;
  margin: 0px;
  overflow: auto;
  padding: 0px;
  position: absolute;
  z-index: 77;
}
.popupW {
  border: solid 1px #363636;
  width: 650px;
  display: inline-block;
  /*height: 1029px;*/
}
.mb_modTitle {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
  text-indent: initial;
  background-image: url(./Images/mbModBg.png);
  background-repeat: repeat-x;
  height: 25px;
  border: solid 1px #363636;
  font-weight: bold;
  font-size: 11px;
  color: #ffffff;
  width: 100%;
}
.mb_modTitle div {
  vertical-align: middle;
}
input[type="checkbox"] {
  vertical-align: middle;
  position: relative;
  bottom: 1px;
}
.tableGrid {
  border: solid 1px #cfd7e0;
  background-color: #e2e9f1;
  font-size: 11px;
}
.GridBorder {
  display: table;
  box-sizing: border-box;
  border-collapse: collapse;
  border: 1px solid #a3b4c6;
  width: 100%;
}
.GridHeader {
  font-size: 11px;
  color: #ffffff;
  font-family: Tahoma, Helvetica, sans-serif;
  background-color: #5678b5;
  font-weight: 400;
}
.GridHeader div {
  vertical-align: middle;
  text-align: center;
  border: 1px solid #a3b4c6;
}
.table_th1_R {
  background-color: #4b3925;
  height: 25px;
}

.table_th2_R {
  background-color: #4b3925;
  height: 25px;
}

.table_th3_R {
  background-color: #4b3925;
  height: 50px;
}

.table_th4_R {
  background-color: #4b3925;
  height: 50px;
}
.table_th1 {
  background-color: #4b3925;
  height: 25px;
}

.table_th2 {
  background-color: #4b3925;
  height: 25px;
}

.table_th3 {
  background-color: #4b3925;
  height: 50px;
}

.table_th4 {
  background-color: #4b3925;
  height: 50px;
}
.GridItem {
  font-weight: normal;
  font-size: 11px;
  color: #a3b4c6;
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  /*background-color: #E9F2FB;*/
  background-color: #e8e8e8;
  /*height: 30px;*/
  height: 32px;
  border: 1px solid #a3b4c6;
}

.GridAltItem {
  font-weight: normal;
  font-size: 11px;
  color: #a3b4c6;
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  /*background-color: #F5F5F5;*/
  background-color: #f8f8f8;
  /*height: 30px;*/
  height: 32px;
  border: 1px solid #a3b4c6;
}

.GridRunItem {
  font-weight: normal;
  font-size: 11px;
  color: #a3b4c6;
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  background-color: #fbcbbd;
  /*height: 30px;*/
  height: 32px;
  border: 1px solid #a3b4c6;
}

.GridAltRunItem {
  font-weight: normal;
  font-size: 11px;
  color: #a3b4c6;
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  background-color: #fdddd2;
  /*height: 30px;*/
  height: 32px;
  border: 1px solid #a3b4c6;
}

.GridMouseOver {
  font-weight: normal;
  font-size: 11px;
  color: black;
  font-family: Tahoma, Helvetica, sans-serif;
  background-color: #ffffcc;
}
.GridItemTD {
  border: 1px solid #a3b4c6;
}
.Event {
  cursor: pointer;
  font-weight: bold;
  font-size: 11px;
  color: #e1e8fb;
  background-color: #8b754c;
}

.EventR {
  cursor: pointer;
  font-weight: bold;
  font-size: 11px;
  color: #e1e8fb;
  background-color: #8b754c;
}
.Heading4 {
  font-weight: bold;
  font-size: 12px;
  color: black;
  font-family: Tahoma, Helvetica, sans-serif;
}

.Heading5 {
  font-weight: bold;
  font-size: 10px;
  color: #000000;
  text-align: center;
}

.Heading6 {
  font-weight: bold;
  font-size: 10px;
  color: blue;
}
.HeadingLIVE {
  color: #b50000;
}
.HeadingLIVE_ENGB {
  color: #b50000;
  font-size: 12px;
}
.HeadingOU_U {
  color: Gray;
  font-size: 11px;
  font-family: Tahoma, Helvetica, sans-serif;
  font-weight: bold;
}
.Heading8 {
  font-weight: bold;
  font-size: 10px;
  color: #666666;
}
.NegOdds {
  font-weight: bold;
  font-size: 11px;
  color: #b50000;
  /*font-family: Tahoma, Helvetica, sans-serif;*/
}

A.NegOdds:link {
  color: #b50000;
  text-decoration: none;
}

A.NegOdds:visited {
  color: #b50000;
  text-decoration: none;
}

A.NegOdds:active {
  color: #b50000;
  text-decoration: none;
}

A.NegOdds:hover {
  color: #b50000;
  text-decoration: none;
}

.PosOdds {
  font-weight: bold;
  font-size: 11px;
  color: #01122b;
}

A.PosOdds:link {
  color: #01122b;
  text-decoration: none;
}

A.PosOdds:visited {
  color: #01122b;
  text-decoration: none;
}

A.PosOdds:active {
  color: #01122b;
  text-decoration: none;
}

A.PosOdds:hover {
  color: #01122b;
  text-decoration: none;
}
.PosOdds label,
.NegOdds label {
  cursor: pointer;
}
.PosOdds label:hover,
.NegOdds label:hover {
  text-decoration: underline;
}
.Give {
  font-weight: bold;
  font-size: 11px;
  color: #b50000;
}
.GBTake {
  font-weight: normal;
  font-size: 11px;
  color: #01122b;
}
.Take {
  font-weight: bold;
  font-size: 11px;
  color: #01122b;
}
.Draw {
  font-weight: bold;
  font-size: 10px;
  color: #606060;
}
.CssImgRC {
  vertical-align: middle;
  padding-bottom: 3px;
}
.HeadingTime {
  font-weight: bold;
  font-size: 9px;
  color: #b50000;
  font-family: Tahoma;
  white-space: nowrap;
}
.HDPBG_GridItem {
  border: 1px solid rgb(163, 180, 198);
}
.SEP_R {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-right: 3px solid rgb(139, 117, 76) !important;
}
.SEP {
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-right: 3px solid rgb(139, 117, 76) !important;
}
.SelMenu {
  border: 1px #b1b1b1 solid;
  background: #f2f2f2;
  text-align: center;
  margin-left: 20px;
  z-index: 9999;
  display: grid;
  grid-template-columns: 50% 50%;
}
.SelMenuItemBg {
  border: #dddddb 1px solid;
  padding: 0 0 0 2px;
}
.SelMenuItemBg:hover {
  border: #dddddb 1px solid;
  background: #dbdbdb;
}
.SelMenuItem {
  color: #3b5061;
  line-height: 15px;
}
.SelMenuItem:link {
  text-decoration: none;
  color: #3b5061;
}
.SelMenuItem:visited {
  text-decoration: none;
  color: #3b5061;
}
.SelMenuItem:hover {
  text-decoration: none;
  color: #3b5061;
}
.SelMenuItem:active {
  text-decoration: none;
  color: #3b5061;
}
.HalfTime {
  font-size: 9px;
  font-weight: bold;
  color: #0000ff;
}
.OddsInjTime {
  font-size: 8px;
  color: #56649e;
  font-family: Tahoma;
  white-space: nowrap;
}
.rightstyle {
  background-color: #e5e5e5;
  height: 100%;
  overflow-y: auto;
}
.accordion {
  margin: 0;
  width: 268px;
  height: 25px;
  padding-left: 10px;
  padding-top: 4px;
  border-left: #000 1px solid;
  border-right: #000 1px solid;
  border-top: #000 1px solid;
  background: url(./Images/MiniCasino/acc-title-bg.jpg) repeat-x;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}

.NewOdds2 {
  -webkit-animation: oddschange 2s infinite; /* Safari 4+ */
  -moz-animation: oddschange 2s infinite; /* Fx 5+ */
  -o-animation: oddschange 2s infinite; /* Opera 12+ */
  animation: oddschange 2s infinite; /* IE 10+, Fx 29+ */
}

@keyframes oddschange {
  0%,
  49% {
    background-color: #ffaf8c;
  }

  50%,
  100% {
    background-color: inherit;
  }
}

.class3 A:link {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
}
.class3 A:visited {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
}
.class3 A:active {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #ffffff;
  text-decoration: none;
  font-weight: normal;
}
.class3 A:hover {
  font-family: Arial;
  font-size: 11px;
  font-style: normal;
  line-height: normal;
  font-variant: normal;
  color: #fc0;
  text-decoration: none;
  font-weight: normal;
}
.tabGridBg1 {
  background-color: #5e5e5e;
}
.tabGridBg2 {
  background-color: #b4b4b4;
}
.tabGridBg3 {
  background-color: #fbf9fa;
}
.tabGridBg4 {
  background-color: #a3b4c6;
}
.tabHeaderBg {
  background-image: url(./Images/tabHBg.png);
  background-repeat: repeat-x;
  height: 30px;
}
.GridHeaderx {
  font-size: 13px;
  color: White;
  font-weight: bold;
}
.Bold {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  color: Black;
  font-weight: bold;
}
.GridTable {
  border: 1px solid #b1b1b1;
}
.GridItemStyle {
  font-size: 11px;
  background-color: #fbf8d5;
}
.l {
  text-align: left;
}
.r {
  text-align: right;
}
.Error {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: red;
}
/************************* Panel BEGIN ****************************/
.PanelItemBg {
  background-color: #e9f3fd;
}
.PanelBetBoxBgRun {
  background-color: #fde3e4;
}
.PanelBetBoxBgToday {
  background-color: #e0e4f0;
}
.txtBetBold {
  font-size: 11px;
  color: #000000;
  font-weight: bold;
}
.BetBoxGrid3 {
  border: solid 1px #8c8c8c;
  background-color: #ffffff;
}
.txtBetBlueBold {
  font-size: 11px;
  color: #000099;
  font-weight: bold;
}
.txtBetRedBold {
  font-size: 11px;
  color: #b50000;
  font-weight: bold;
}
#socBetTeam > span {
  display: inline-block;
  max-width: 160px;
  word-break: break-word;
}
.txtPosOdds {
  font-size: 15px;
  color: #000000;
  font-weight: bold;
}
.txtNegOdds {
  font-size: 15px;
  color: #b50000;
  font-weight: bold;
}
.txtBet {
  font-size: 11px;
  color: #000000;
  font-weight: normal;
}
.txtBetBlue {
  font-size: 11px;
  color: #01122b;
}
.txtBetRed {
  font-size: 11px;
  color: #01122b;
}
.txtModuleTitle {
  color: #003399;
}
.BetBoxBg2 {
  background-color: #c9c9d3;
  padding: 1px;
}
.BetBoxBg4 {
  background-color: #c9c9d3;
  font-size: 10px;
}
.BetBoxBg5 {
  background-color: #ffffff;
}
.BetTextBox {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
}
.BetBoxBg5 {
  background-color: #ffffff;
}
.txtBlue {
  color: #003399;
}
.txtRed {
  color: #ff0000;
}
.GridItemBet {
  font-weight: normal;
  font-size: 11px;
  color: black;
  font-family: Tahoma, Helvetica, sans-serif;
  height: 25px;
  background-color: #becdea;
}
.GridRunItemBet {
  font-weight: normal;
  font-size: 11px;
  color: black;
  font-family: Tahoma, Helvetica, sans-serif;
  height: 25px;
  background-color: #fdddd2;
}
.title-T {
  color: #000099;
  font-weight: bold;
}
.title-R {
  color: #b50000;
  font-weight: bold;
}
.border-T {
  border-left: 2px solid #000099;
}
.border-R {
  border-left: 2px solid #b50000;
}
.Boldbg {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  color: Black;
  font-weight: bold;
  background-color: #90ee90;
}
/************************* Panel END ****************************/
.PanelSubHdrBg {
  background-image: url(./Images/p1.png);
  background-repeat: no-repeat;
  width: 174px;
  height: 24px;
}
.PanelStakeLbl {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  padding-left: 30px;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}
.StakeBg2 {
  background-color: #e4e4e4;
}
.StakeRunBg {
  background-color: #fdddd2;
}
.PanelStakeGrid {
  border: solid 1px #c0c0c0;
  border-collapse: collapse;
}

.Rejected {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: white;
  background-color: red;
}
.Accepted2 {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: white;
  background-color: green;
}
.Waiting {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: black;
  background-color: yellow;
}
.Hdp {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: Red;
}

.OU {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: Blue;
}

.GBHdp {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: Red;
}

.GBOU {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: Blue;
}

.GBOver {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  /*font-weight: normal;*/
  color: red;
}

.Over {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: red;
}

A.Over:link {
  text-decoration: none;
  color: red;
}

A.Over:visited {
  text-decoration: none;
  color: red;
}

A.Over:active {
  text-decoration: none;
  color: red;
}

A.Over:hover {
  text-decoration: underline;
  color: red;
}

.GBUnder {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  /*font-weight: normal;*/
  color: black;
}

.Under {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: black;
}

A.Under:link {
  text-decoration: none;
  color: black;
}

A.Under:visited {
  text-decoration: none;
  color: black;
}

A.Under:active {
  text-decoration: none;
  color: black;
}

A.Under:hover {
  text-decoration: underline;
  color: black;
}

.Pause {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: Gray;
}

.GBPause {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: Gray;
}

.Error {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: red;
}
.OddsChgBlink,
.BetBoxBlink {
  background-image: url(./Images/oddsChgBlink.gif);
}

.GridHeaderStyle {
  color: #ffffff;
  font-size: 11px;
  background-image: url(./Images/table_th1.png);
  background-repeat: repeat-x;
  height: 27px;
}
.GridStyle {
  border-color: #a3b4c6;
  background-color: #a3b4c6;
}
.GridAltItemStyle {
  font-size: 11px;
  background-color: #ede6d3;
}

/************************* BetList BEGIN ****************************/
.Hd2 {
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: normal;
}
.Hd2B {
  font-size: 11px;
  font-family: Arial, Helvetica, sans-serif; /*font-weight: bold;*/
}
.HdX12 {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px; /*font-weight: normal;*/
  font-weight: bold;
  color: blue;
}
.HdgbX12 {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: blue;
}
.HdParlay {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: green;
}
.Hdgbparlay {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: green;
}
.HdGive {
  font-weight: bold;
  font-size: 11px;
  color: red;
  font-family: Tahoma, Helvetica, sans-serif;
}
.HdTake {
  font-weight: bold;
  font-size: 11px;
  color: blue;
  font-family: Tahoma, Helvetica, sans-serif;
}
.HdGBOver {
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  font-size: 11px;
  /*font-weight: bold;*/
  color: red;
}

.HdOver {
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  font-size: 11px;
  font-weight: bold;
  color: red;
}

.HdGBUnder {
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  font-size: 11px;
  /*font-weight: bold;*/
  color: black;
}

.HdUnder {
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  font-size: 11px;
  font-weight: bold;
  color: black;
}

.Accepted {
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  font-size: 11px;
  font-weight: bold;
  color: Blue;
}

.Pending {
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  font-size: 11px;
  font-weight: bold;
  color: Yellow;
}

.GBGive4 {
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  font-size: 11px;
  font-weight: bold;
  color: #cc0000;
}

A.GBGive4:link {
  text-decoration: none;
  color: #cc0000;
}

A.GBGive4:visited {
  text-decoration: none;
  color: #cc0000;
}

A.GBGive4:active {
  text-decoration: none;
  color: #cc0000;
}

A.GBGive4:hover {
  text-decoration: underline;
  color: #cc0000;
}

.GBTake4 {
  /*font-family: Tahoma, Helvetica, sans-serif;*/
  font-size: 11px;
  font-weight: bold;
  color: #000099;
}

A.GBTake4:link {
  text-decoration: none;
  color: #000099;
}

A.GBTake4:visited {
  text-decoration: none;
  color: #000099;
}

A.GBTake4:active {
  text-decoration: none;
  color: #000099;
}

A.GBTake4:hover {
  text-decoration: underline;
  color: #000099;
}
.BtnNormal {
  font-size: 12px;
  color: #294983;
}
.Negative {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: Red;
}
.Hd9 {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  color: #000000;
  font-weight: bold;
}
.Positive {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: black;
}
.Link2 {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  color: blue;
  text-decoration: none;
  font-weight: bold;
  font-style: normal;
}

A.Link2:link {
  text-decoration: none;
  color: blue;
}

A.Link2:visited {
  text-decoration: none;
  color: blue;
}

A.Link2:active {
  text-decoration: none;
  color: blue;
}

A.Link2:hover {
  text-decoration: underline;
  color: blue;
}
.ddlLang {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
}
.GridHomeStyle {
  color: #e1e8fb;
  font-size: 11px;
  font-weight: bold;
  background-color: #8b6b30;
  border: none;
}

.GridScore {
  font-weight: bold;
  font-size: 11px;
  color: black;
}
.GridScore_B {
  font-weight: bold;
  font-family: Tahoma;
  font-size: 11px;
  color: black;
}
.GridScore_N {
  font-weight: normal;
  font-family: Tahoma;
  font-size: 11px;
  color: black;
}
/************************* BetList END ****************************/

.bold {
  font-weight: normal;
  font-size: 12px;
  color: black;
  font-family: Tahoma, Helvetica, sans-serif;
}
.Grid {
  border-collapse: collapse;
}

.loader-container-bet {
  text-align: center;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  top: 0px;
  left: 0px;
  background-color: #8b754cdb;
  justify-content: center;
  align-items: center;
}
.TakeBet {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #000099;
}
.GiveBet {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #cc0000;
}
.BoldBet {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  color: Black;
  font-weight: bold;
}
.gb {
  font-weight: normal;
}
.gbX12 {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  /*font-weight: normal;*/
  color: blue;
}
.Bold2 {
  font-weight: bold;
}
/* Acc Match Trans */
.HomeGiveTrue {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #ff0000;
}

.HomeGiveFalse {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #0000ff;
}
.Normal {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: black;
}
/* Acc Match Trans END */

.buttonPanel {
  width: 78px;
  height: 19px;
  background-image: linear-gradient(#fbfbfb, #dcdcdc);
  border: 1px solid #b8b8b8;
  cursor: pointer;
  display: block;
  line-height: 19px;
  border-radius: 2px;
  font-size: 8pt;
}

.buttonPanel:hover {
  background-image: linear-gradient(#ffa243, #f37744);
  border: 1px solid #d1581f;
  color: #fff;
}
.Normal_NoBold {
  font-family: Tahoma, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: normal;
  color: black;
}
.selectedOdds {
  background-color: orange !important;
}